@import "./colors.scss";
@import "./glitch-effect.scss";

* {
  box-sizing: border-box;
}

.main-nav {
  padding-top: 25px;
  width: 100%;
  position: sticky;
  color: white;

  //glitch
  .nav-logo {
    font-weight: 500;
    font-size: 32px;
    position: relative;
    @include textGlitch("example-one", 50, white, black, red, blue, 450, 115);
  }
}

@media only screen and (max-width: 600px) {
  .header-content {
    h1 {
      font-size: 40px;
    }
    h2 {
      font-size: 40px;
    }
  }
}

.header-section {
  padding-left: 50px;
  padding-right: 50px;
  background-image: url("../assets/background-image-white.png");
  background-size: cover;
  height: 100vh;

  .header-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      color: white;
      font-size: 100px;
      margin-block-start: 0;
      margin-block-end: 0;
    }

    @media screen and (max-width: 600px) {
      h1 {
        font-size: 60px;
      }
    }

    @media screen and (max-width: 400px) {
      h1 {
        font-size: 40px;
      }
    }

    h2 {
      -webkit-text-stroke: 2px white;
      font-size: 100px;
      margin-block-start: 0;
      margin-block-end: 0;
    }

    @media screen and (max-width: 600px) {
      h2 {
        font-size: 60px;
      }
    }
    
    @media screen and (max-width: 400px) {
      h2 {
        font-size: 40px;
      }
    }
  }
}

.content-section-one {
  background-color: $background;
  color: white;
}
